import React, { useEffect, useState } from 'react'
import Footer from 'swp-components/lib/components/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Newrelic from '@/components/newrelic-agent'
import NewsDetail from '@/components/news-detail'
import Seo from '@/components/seo'
import useHomePageDataLayer from '@/hooks/data-layers/use-home-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const CatNewsDetail = ({ serverData, location }) => {
  let [isWindowDefined, setIsWindowDefined] = useState(false)
  let [
    seo,
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    threeLatestNews,
    newsDetail,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)
  let { handleMessage, createScopeProps } = useHomePageDataLayer()

  useEffect(() => {
    if (slug.slug == 'news-tol-002') {
      window.location.href = 'https://www.true.th/true-online/news/discount-coupon';
    }
    setIsWindowDefined(true)
  }, [isWindowDefined])

  let slug = serverData.slug
  if (!slug || !slug.cat || slug.cat === '' || !slug.slug || slug.slug === '') {
    return <NotFoundPage />
  }
  if (newsDetail == null || newsDetail.length <= 0) {
    return <NotFoundPage />
  }

  newsDetail[0].category = []
  newsDetail[0].tags.map(a => {
    newsDetail[0].category.push({
      categoryName: a.name,
      categoryLink: `${serverData.locale !== 'th' ? '/en' : ''}/news-tag/${
        a.slug
      }`,
      link: {
        url: `${serverData.locale !== 'th' ? '/en' : ''}/news-tag/${a.slug}`,
        newTab: true,
      },
    })
  })
  newsDetail[0].link = {
    url: `${serverData.locale !== 'th' ? '/en' : ''}/news/${slug.cat}/${
      newsDetail.slug
    }`,
    newTab: true,
  }
  newsDetail[0].button = {
    buttonName: 'กลับ',
    link: {
      url: `${serverData.locale !== 'th' ? '/en' : ''}/news/${slug.cat}`,
      newTab: false,
    },
  }
  newsDetail[0].startDate = newsDetail[0].updatedAt
  newsDetail[0].description = newsDetail[0].content
  newsDetail[0].currentURL = location.href

  threeLatestNews.map(a => {
    a.link = {
      url: `${serverData.locale !== 'th' ? '/en' : ''}/news/${slug.cat}/${
        a.slug
      }`,
      newTab: true,
    }
    a.startDate = a.updatedAt
  })
  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={newsDetail[0].seo} script={headerScript.script} />
        <Header items={brandHeaders} services={productServices} />

        {isWindowDefined && newsDetail[0] && (
          <NewsDetail
            article={newsDetail[0]}
            news={threeLatestNews}
            locale={serverData.locale}
            path={slug.cat}
          />
        )}

        <Footer className="pb-4" items={footerHeaders} />
        <ChatShop
          content={chatShop}
          mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
          mariConfig={mariConfig}
        />
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default CatNewsDetail

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents([
    `seo?_locale=${pageContext.locale}`,
    `header-script?_locale=${pageContext.locale}`,
    `brand-headers?_locale=${pageContext.locale}`,
    `product-services?_locale=${pageContext.locale}`,
    `footer-headers?_locale=${pageContext.locale}`,
    `chat-and-shop?_locale=${pageContext.locale}`,
    `news-and-events/?page_group.slug=${params.cat}&_start=0&_limit=3&slug_ne=${params.slug}&_locale=${pageContext.locale}`,
    `news-and-events/?page_group.slug=${params.cat}&slug=${params.slug}&_locale=${pageContext.locale}`,
    'mari-chat-and-shop-config',
  ])

  let slug = params
  return {
    props: { contents, slug: slug, locale: pageContext.locale },
  }
}
